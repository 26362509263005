const openFilter = (filterName) => {
  filterName.classList.add('open');
  filterName.parentNode.querySelector('.filter-list').classList.add('open');
  filterName.parentNode.style.zIndex = 2;
  if(filterName.classList.contains('with-blur')) {
    document.querySelector('body').classList.add('menu-open');
    document.querySelector('.header-page-title-fixed').style.zIndex = 2;
  }
}

const closeFilter = (filterName) => {
  filterName.classList.remove('open');
  filterName.parentNode.querySelector('.filter-list').classList.remove('open');
  filterName.parentNode.style.zIndex = '';
  if(filterName.classList.contains('with-blur')) {
    document.querySelector('body').classList.remove('menu-open');
    document.querySelector('.header-page-title-fixed').style.zIndex = '';
  }
}

export const initFilters = () => {
  const filters = document.querySelectorAll('.filter .filter-name');
  if(filters) {
    filters.forEach(filter => {
      filter.addEventListener('click', event => {
        if(filter.classList.contains('open')) {
          closeFilter(filter);
        } else {
          openFilter(filter);
        }
      })
    })
  }
}

export const initInfoBtn = () => {
const infoBtn = document.querySelector(".info-btn");
  if(infoBtn) {
    infoBtn.addEventListener('click', function(){
      // $('body').toggleClass("dark light");
      $('#project-page').toggleClass("info-open");
    });
  }
}