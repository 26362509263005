import lozad from 'lozad';

export const loadImages = (locoScroll) => {
  if(document.querySelector('.lozad')){
    const observer = lozad('.lozad', {
      rootMargin: '10px 0px',
      threshold: 0.1,
      enableAutoReload: true,
      loaded: function(el) {
        el.classList.add('img-lazyloaded');
        if(el.parentNode.classList.contains('lab-item')) {
          el.parentNode.classList.add('lazyloaded');
        }
        if(locoScroll){
          setTimeout(() => {
            locoScroll.update();
          }, 200);
        }
      }
    });
    observer.observe();

    return observer;
  } else { return null; }
}

