import LocomotiveScroll from 'locomotive-scroll';

export const initLocoScroll = () => {
  const locoScrollContainer = document.querySelector('[data-scroll-container]');
  if(locoScrollContainer) {
    const locoScroll = new LocomotiveScroll({
      el: locoScrollContainer,
      smooth: true,
      multiplier: 0.4,
      touchMultiplier: 1.4
    });

    let currentLimit = 0

    locoScroll.on('scroll', (args) => {
      if(args.limit.y !== currentLimit && args.scroll.y > args.limit.y - 300) {
        currentLimit = args.limit.y
        locoScroll.update();
      }
    });

    return locoScroll;
  } else {
    return null;
  }
}