// import Filterizr from 'filterizr';

// let trigger;

function hide(elem) {
  gsap.to(elem, { blur: 6, translateZ: 0, duration: 0.2 });
}

function show(elem) {
  if(!elem) return;

  gsap.to(elem, { blur: 0, translateZ: 0, duration: 0.2 });
  const bgImgInactive = document.querySelector('.background-img.inactive .lozad');

  const bgImgContInactive = document.querySelector('.background-img.inactive');
  const bgImgContActive = document.querySelector('.background-img.active');

  if(!bgImgInactive) return;

  bgImgInactive.dataset['src'] = elem.dataset['background'];
  bgImgInactive.src = elem.dataset['background'];

  bgImgContActive.classList.replace('active', 'inactive')
  bgImgContInactive.classList.replace('inactive', 'active')
}

export const initProjects = () => {
  let currentVisibleItem = 0;
  let isScrolling = false;
  let lastHoverItemKey = 0;
  const snapItems = gsap.utils.toArray(".snap-item");
  snapItems.forEach(elem => {
    hide(elem)
    elem.querySelector('h2').addEventListener('mouseenter', (e) => {
      if(!isScrolling) {
        const targetEl = e.target.closest('.snap-item');
        if(lastHoverItemKey !== parseInt(targetEl.dataset['key'])) {
          const prevEl = snapItems[lastHoverItemKey];
          lastHoverItemKey = parseInt(targetEl.dataset['key']);
          // hide(currentItem);
          hide(prevEl);
          if(currentVisibleItem !== lastHoverItemKey){
            hide(snapItems[currentVisibleItem]);
          }
          show(targetEl);
          currentVisibleItem = parseInt(targetEl.dataset['key']);
        }
      }
    });
    // elem.querySelector('h2').addEventListener('mouseleave', (e) => {
    //   const targetEl = e.target.closest('.snap-item');
    //   if(currentItem.dataset['key'] !== targetEl.dataset['key']) {
    //     hide(targetEl);
    //     show(currentItem);
    //   }
    // });
  });
  const itemsLength = snapItems.length;

  let snapItemHeight;

  const tolerance = window.innerWidth < 992 ? 20 : 0;

  if(document.querySelector('.snap-item')) {
    const snapItemEl = document.querySelector('.snap-item')
    snapItemHeight = snapItemEl.clientHeight;
    snapItemHeight += parseInt(window.getComputedStyle(snapItemEl).getPropertyValue('margin-top'));
  }

  const calculateOffset = (snapIndex) => {
    return -(snapItemHeight * snapIndex);
  }

  let currentItemIndex = 0;
  let currentItem = snapItems[currentItemIndex];
  show(currentItem);
  gsap.to(snapItems, { duration: 0.25, y: calculateOffset(currentItemIndex) })

  const prev = () => {
    isScrolling = true;
    if(currentVisibleItem === currentItemIndex){
      hide(currentItem);
    } else {
      hide(snapItems[currentVisibleItem]);
    }
    currentItemIndex--;
    currentItem = snapItems[currentItemIndex];
    show(currentItem);
    currentVisibleItem = currentItemIndex;
    gsap.to(snapItems, { duration: 0.25, y: calculateOffset(currentItemIndex), onComplete: () => isScrolling = false })
  }

  const next = () => {
    isScrolling = true;
    if(currentVisibleItem === currentItemIndex){
      hide(currentItem);
    } else {
      hide(snapItems[currentVisibleItem]);
    }
    currentItemIndex++;
    currentItem = snapItems[currentItemIndex];
    show(currentItem);
    currentVisibleItem = currentItemIndex;
    gsap.to(snapItems, { duration: 0.25, y: calculateOffset(currentItemIndex), onComplete: () => isScrolling = false });
  }

  let t1 = Date.now();
  let skipCount = 0;

  const willSkip = () => {
    const t2 = Date.now();
    const tDiff = t2 - t1;
    t1 = t2;
    // console.log(tDiff);
    // console.log(skipCount);
    if(tDiff < 150 && skipCount < 20) {
      skipCount++;
      return true;
    } else if(tDiff < 150 && skipCount >= 20) {
      skipCount = 0;
      return false;
    }
    else {
      skipCount = 0;
      return false;
    }
  }

  let minScrollWheelInterval = 100; // minimum milliseconds between scrolls
  let lastScrollWheelTimestamp = 0;
  let lastScrollWheelVelocity = 0;

  const detectInertiaScroll = (velocity) => {
    const now = Date.now();

    const rapidSuccession = now - lastScrollWheelTimestamp < minScrollWheelInterval;
    const otherDirection = (lastScrollWheelVelocity > 0) !== (velocity > 0);
    const speedDecrease = Math.abs(velocity) < Math.abs(lastScrollWheelVelocity);

    const isHuman = otherDirection || !rapidSuccession || !speedDecrease;

    lastScrollWheelTimestamp = now;
    lastScrollWheelVelocity = velocity;

    return !isHuman;
  }

  // ScrollTrigger.normalizeScroll(true)
  ScrollTrigger.observe({
    tolerance: tolerance,
    onUp: (self) => {
      // console.log("UP");
      // console.log("velocity:", self.velocityY, "delta:", self.deltaY);
      const inertiaScroll = detectInertiaScroll(self.velocityY);
      if(currentItemIndex > 0 && !self.isDragging && !willSkip() && !inertiaScroll) {
        prev();
      }
      else if (currentItemIndex < itemsLength - 1 && self.isDragging && !willSkip()) {
        next();
      }
    },
    onDown: (self) => {
      // console.log("DOWN");
      // console.log('currentItemIndex ' + currentItemIndex + ' itemsLength - 1 ' + (itemsLength - 1));
      // console.log("velocity:", self.velocityY, "delta:", self.deltaY);

      const inertiaScroll = detectInertiaScroll(self.velocityY);
      if(currentItemIndex < itemsLength - 1 && !self.isDragging && !willSkip() && !inertiaScroll) {
        next();
      }
      else if(currentItemIndex > 0 && self.isDragging && !willSkip()) {
        prev();
      }
    },
    // onChange: (self) =>  {
    //   console.log("velocity:", self.velocityX, self.velocityY, "delta:", self.deltaX, self.deltaY, "target element:", self.target, "last event:", self.event);
    // }
  });

  // document.addEventListener('touchstart', (e) => { e.preventDefault() });
  // document.addEventListener('touchend', (e) => { e.preventDefault() });
  // document.addEventListener('touchmove', (e) => { e.preventDefault() });
  // document.addEventListener('touchcancel', (e) => { e.preventDefault() });
}

export const killTriggers = () => {
  let triggers = ScrollTrigger.getAll();
  for (let i = 0; i < triggers.length; i++) {
    triggers[i].kill(true)
  }
}

export const initProjectFilters = () => {
  // const options = {
  //   animationDuration: 0.7, // in seconds
  //   callbacks: {
  //     onFilteringStart: function() {
  //       killTriggers();
  //     },
  //     onFilteringEnd: function() {
  //       initProjects();
  //     },
  //     onShufflingStart: function() { },
  //     onShufflingEnd: function() { },
  //     onSortingStart: function() { },
  //     onSortingEnd: function() { }
  //   },
  //   // controlsSelector: '.filter-list.services', // Selector for custom controls
  //   // delay: 250, // Transition delay in ms
  //   // delayMode: 'alternate', // 'progressive' or 'alternate'
  //   // easing: 'ease-out',
  //   filter: 'all', // Initial filter
  //   filterOutCss: { // Filtering out animation
  //     opacity: 0
  //   },
  //   filterInCss: { // Filtering in animation
  //     opacity: 1
  //   },
  //   gridItemsSelector: '.snap-item',
  //   // gutterPixels: 0, // Items spacing in pixels
  //   layout: 'vertical', // See layouts
  //   // multifilterLogicalOperator: 'or',
  //   // searchTerm: '',
  //   // setupControls: false, // Should be false if controlsSelector is set
  //   // spinner: { // Configuration for built-in spinner
  //   //   enabled: false,
  //   //   fillColor: '#2184D0',
  //   //   styles: {
  //   //     height: '75px',
  //   //     margin: '0 auto',
  //   //     width: '75px',
  //   //     'z-index': 2,
  //   //   },
  //   // },
  // }

  // const filterizr = new Filterizr('.snap-scroller', options);

  // // const filterLinks = document.querySelectorAll('.filter-list.services li a');
  // // filterLinks.forEach(el => {
  // //   el.addEventListener('click', (e) => {
  // //     e.preventDefault();
  // //     console.log('clicked ' + e.target.dataset['id']);
  // //     const filterId = parseInt(e.target.dataset['id']);
  // //     document.querySelectorAll('.snap-item').forEach((el) => {
  // //       const filterIds = JSON.parse(el.dataset['filterids']);
  // //       if(filterIds.includes(filterId) || filterId === 0) {
  // //         console.log('found ' + el);
  // //         el.classList.add('pass-filter');
  // //         el.classList.remove('fail-filter');
  // //         gsap.to(el, { opacity: 0.8, duration: 0.3, display:"flex" });
  // //       } else {
  // //         el.classList.add('fail-filter');
  // //         el.classList.remove('pass-filter');
  // //         gsap.to(el, { opacity: 0, duration: 0.3, display:"none" });
  // //       }
  // //     });
  // //     killTriggers();
  // //     initProjects();
  // //   })
  // // });
}

