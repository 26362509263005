console.log('Hello World from Webpacker')

import { initSlider, initProjectPageSlider } from '../slider';
import { initLocoScroll } from '../smooth-scroll';
import { initProjects, killTriggers, initProjectFilters } from '../projects';

import Plyr from 'plyr';
import barba from '@barba/core';
import { initFilters, initInfoBtn } from '../click-actions';
import { loadImages } from '../lazy-images';
import { startClock } from '../clock';
import Bowser from 'bowser';

let currentSlider = null;

const hideFooter = () => {
  const footerEl = document.querySelector('footer');
  footerEl.style.display = 'none';
}

const showFooter = () => {
  const footerEl = document.querySelector('footer');
  footerEl.style.display = 'block';
}

document.addEventListener("DOMContentLoaded", function() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  document.querySelector('body').classList.add(browser.getBrowserName().toLowerCase());
  document.querySelector('body').classList.add(browser.getOSName().toLowerCase());

  if(document.querySelector('.home-swiper')){
    initSlider();
  }

  const player = new Plyr('#player', { muted: true, autoplay: true });
  // player.play();

  if(document.querySelector('.project-swiper')){
    console.log('[DOMContentLoaded] Will init project slider')
    const players = Array.from(document.querySelectorAll('.vimeo-player')).map((p) => new Plyr(p));
    initProjectPageSlider(players);
  }

  if(document.querySelector('.filter')){
    initFilters();
  }

  if(document.querySelector('#projects-page')){
    initProjects();
    initProjectFilters();
  }

  if(document.querySelector('.info-btn')){
    initInfoBtn();
  }

  let locoScroll = initLocoScroll();
  let observer = loadImages(locoScroll);

  barba.hooks.leave(() => {
    window.scrollTo({ top: 0, left: 0, behaviour: 'auto' });
  });

  barba.hooks.afterEnter((data) => {
    console.log('BODY: ' + barba.wrapper.classList)
    console.log('MAIN: ' + data.next.container.classList)


    if(data.next.container.classList.contains('light')) {
      barba.wrapper.classList.remove('dark');
    } else if(data.next.container.classList.contains('dark')) {
      barba.wrapper.classList.add('dark');
    }
  });

  let clock;

  barba.hooks.after(() => {
    locoScroll = initLocoScroll();
    observer = loadImages(locoScroll);

    if(document.querySelector('.home-swiper')){
      initSlider();
    }

    if(document.querySelector('.project-swiper')){
      console.log('[Hooks] Will init project slider')
      const players = Array.from(document.querySelectorAll('.vimeo-player')).map((p) => new Plyr(p));
      initProjectPageSlider(players);
    }

    if(document.querySelector('.filter')){
      initFilters();
    }

    if(document.querySelector('#projects-page')){
      initProjects();
      initProjectFilters();
    }

    if(document.querySelector('.info-btn')){
      initInfoBtn();
    }
  });

  barba.init({
    debug: true,
    transitions: [{
      name: 'opacity-transition',
      leave(data) {
        return new Promise(resolve => {
          gsap.to(data.current.container, {
            opacity: 0,
            blur: 15,
            duration: 0.5,
            onComplete: () => { resolve(); }
          });
        });
      },
      enter(data) {
        gsap.from(data.next.container, {
          opacity: 0,
          blur: 15,
          duration: 0.4,
          onComplete: () => {
            data.next.container.style.removeProperty('filter');
          }
        });
      },
    }],
    views: [
      {
        namespace: 'pages-home',
        beforeEnter() {},
        afterEnter() {
          document.querySelector('body').classList.add('home');

          var vids = document.querySelectorAll("video");
          vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });

          const player = new Plyr('#player', { muted: true, autoplay: true });
        },
        afterLeave(){
          document.querySelector('body').classList.remove('home');
        }
      },
      {
        namespace: 'pages-lab',
        beforeEnter() {},
        afterEnter(data) {

        },
        beforeLeave() {
          locoScroll.destroy();
        }
      },
      {
        namespace: 'pages-contact',
        afterEnter() {
          clock = startClock();
        },
        beforeLeave() {
          clearInterval(clock);
        }
      },
      {
        namespace: 'articles-index',
        afterEnter() {
        },
        beforeLeave() {
          locoScroll.destroy();
        }
      },
      {
        namespace: 'articles-show',
        afterEnter() {
        },
        beforeLeave() {
          locoScroll.destroy();
        }
      },
      {
        namespace: 'pages-about',
        afterEnter() {
        },
        beforeLeave() {
          locoScroll.destroy();
        }
      },
      {
        namespace: 'pages-studio_life',
        afterEnter() {
        },
        beforeLeave() {
          locoScroll.destroy();
        }
      },
      {
        namespace: 'projects-index',
        afterEnter() {
          document.querySelector('body').classList.add('projects', 'index');
        },
        beforeLeave() {
          document.querySelector('body').classList.remove('projects', 'index');
          killTriggers();
        }
      },
      {
        namespace: 'projects-show',
        afterEnter() {
          document.querySelector('body').classList.add('projects', 'show');

          var vids = document.querySelectorAll("video");
          vids.forEach(vid => { var playPromise = vid.play(); if (playPromise !== undefined) { playPromise.then(_ => {}).catch(error => {}); }; });
        },
        beforeLeave() {
          // document.querySelector('body').classList.remove('projects', 'show');
        },
        afterLeave() {
          document.querySelector('body').classList.remove('projects', 'show');
        }
      }
    ]
  });

  console.log('Page loaded!');
});
