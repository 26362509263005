// import Swiper JS
import Swiper from 'swiper';

// import PDFObject from "pdfobject";

// import Swiper styles
// import style from 'swiper/swiper-bundle.min.css';
// console.log(style);

export const initSlider = () => {
  var slidesLength = document.querySelectorAll('.swiper-slide').length;

  // Swiper.use([Autoplay, Lazy, EffectCreative]);
  var mySwiper = new Swiper('.home-swiper', {
    slidesPerView: 1.2,
    // slidesPerView: "auto",
    direction: "horizontal",
    // spaceBetween: 30,
    modules: [Lazy],
    // effect: "creative",
    // creativeEffect: {
    //   prev: {
    //     shadow: true,
    //     translate: ["-20%", 0, -1],
    //   },
    //   next: {
    //     translate: ["100%", 0, 0],
    //   },
    // },
    // effect: 'fade',
    // fadeEffect: {
    //   crossFade: true
    // },
    // centeredSlides: true,
    // loop: true,
    grabCursor: false,
    speed: 500,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3,
    },
    watchSlidesVisibility: true,
    breakpoints: {
      1025: {
        direction: "horizontal",
        speed: 1000,
        slidesPerView: 1.1,
      }
    },
  });

  const isMobile = (window.innerWidth < 1025);
  const mobileSlideTitle = document.querySelector('.mobile-slide-title');

  const prevSlideBtn = document.querySelector('.prev-slide-btn');
  const nextSlideBtn = document.querySelector('.next-slide-btn');

  prevSlideBtn.classList.add('at-edge');

  const homeText = document.querySelector('.home-text');
  const siteMenu = document.querySelector('.site-menu');

  // if (mySwiper.isBeginning) {
  //   siteMenu.classList.add('gi');
  // }

  const slideTitle = document.querySelector('.header-page-title-fixed');
  slideTitle.innerHTML = mySwiper.slides[mySwiper.realIndex].dataset['title'];

  slideTitle.addEventListener("mouseenter", () => {
    if (!mySwiper.isBeginning && !isMobile) {
      document.querySelector('.swiper-slide-active').classList.add('overlay-visible');
      // if(isMobile){
      //   mobileSlideTitle.classList.toggle('revealed');
      // }
    }
  });

  slideTitle.addEventListener("mouseleave", () => {
    if(!isMobile){
      document.querySelector('.swiper-slide-active').classList.remove('overlay-visible');
    }
    // if(isMobile){
    //   mobileSlideTitle.classList.toggle('revealed');
    // }
  });

  slideTitle.addEventListener("click", () => {
    if(isMobile){
      document.querySelector('.swiper-slide-active').classList.toggle('overlay-visible');
      mobileSlideTitle.classList.toggle('revealed');
    }
  });

  const slideTextOffset = window.innerWidth > 1024 ? -1000 : -500;
  const slideTextDuration = window.innerWidth > 1024 ? 0.75 : 0.5;

  mySwiper.on('slideChange', function () {
    if (mySwiper.isBeginning) {
      prevSlideBtn.classList.add('at-edge');
      // siteMenu.classList.add('gi');
    } else if (mySwiper.isEnd) {
      nextSlideBtn.classList.add('at-edge');
    } else {
      prevSlideBtn.classList.remove('at-edge');
      nextSlideBtn.classList.remove('at-edge');
      // siteMenu.classList.remove('gi');
    }

    if(mySwiper.realIndex === 0){
      gsap.to(homeText, { duration: slideTextDuration, x: 0 });

      slideTitle.innerHTML = 'D'
    } else {
      if(mySwiper.realIndex === 1){
        gsap.to(homeText, { duration: slideTextDuration, x: slideTextOffset });
      }

      if(isMobile){
        slideTitle.innerHTML = `D${mySwiper.realIndex.toString().padStart(2, '0')}`;
        mobileSlideTitle.innerHTML = `<a href='${mySwiper.slides[mySwiper.realIndex].dataset['url']}'>${mySwiper.slides[mySwiper.realIndex].dataset['title']}</a>`;

        mobileSlideTitle.classList.remove('revealed');
        const visibleOverlay = document.querySelector('.overlay-visible')
        if(visibleOverlay) {
          visibleOverlay.classList.remove('overlay-visible');
        }
      } else {
        slideTitle.innerHTML = `<a href='${mySwiper.slides[mySwiper.realIndex].dataset['url']}'>D${mySwiper.realIndex.toString().padStart(2, '0')} <br> <span class='slide-title'>${mySwiper.slides[mySwiper.realIndex].dataset['title']}</span></a>`;
      }
    }
  });

  nextSlideBtn.addEventListener('click', function(){
    mySwiper.slideNext();
  });

  prevSlideBtn.addEventListener('click', function(){
    mySwiper.slidePrev();
  });

  return mySwiper;
}

export const initProjectPageSlider = (players) => {
  const slidesLength = document.querySelectorAll('.project-swiper .swiper-slide').length;
  const slideCounter = document.querySelector('.swiper-counter');
  slideCounter.innerHTML = `1 / ${slidesLength}`;

  const prevSlideBtn = document.querySelector('.prev-slide-btn');
  const nextSlideBtn = document.querySelector('.next-slide-btn');

  prevSlideBtn.classList.add('at-edge');

  const projectSwiper = new Swiper('.project-swiper', {
    // slidesPerView: 1.1,
    slidesPerView: "auto",
    spaceBetween: -1,
    // modules: [Lazy],
    // effect: "creative",
    // creativeEffect: {
    //   prev: {
    //     shadow: true,
    //     translate: ["-20%", 0, -1],
    //   },
    //   next: {
    //     translate: ["100%", 0, 0],
    //   },
    // },
    // effect: 'fade',
    // fadeEffect: {
    //   crossFade: true
    // },
    // centeredSlides: true,
    // loop: true,
    grabCursor: false,
    speed: 500,
    // autoplay: {
    //   delay: 3000,
    //   disableOnInteraction: false,
    // },
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    // preloadImages: false,
    // lazy: true,
    // lazy: {
    //   loadPrevNext: true,
    //   // loadPrevNextAmount: 3,
    // },
    watchSlidesVisibility: true,
    breakpoints: {
      1025: {
        speed: 1000,
      }
    },
    on: {
      afterInit: function () {
        console.log('[ProjectSwiper] AfterInit')
        document.querySelectorAll('.project-swiper .swiper-slide img').forEach(item => {
          item.style.transition = 'opacity 1.2s';
          item.style.opacity = '1';
        });
      },
    },
  });

  projectSwiper.on('reachEnd', () => {
    console.log('[ProjectSwiper] reachEnd')
    projectSwiper.snapGrid = [...projectSwiper.slidesGrid];
    // slideCounter.innerHTML = `${slidesLength} / ${slidesLength}`;
  })

  projectSwiper.on('slideChange', () => {
    slideCounter.innerHTML = `${projectSwiper.realIndex + 1} / ${slidesLength}`;

    if (projectSwiper.isBeginning) {
      prevSlideBtn.classList.add('at-edge');
      nextSlideBtn.classList.remove('at-edge');
    } else if (projectSwiper.isEnd) {
      prevSlideBtn.classList.remove('at-edge');
      nextSlideBtn.classList.add('at-edge');
    } else {
      prevSlideBtn.classList.remove('at-edge');
      nextSlideBtn.classList.remove('at-edge');
    }
  });

  projectSwiper.on('transitionEnd', function() {
    if(players) {
      console.log(players)
      const vimeoCont = document.querySelector('.swiper-slide-active .vimeo-container')
      if(vimeoCont) {
        const key = vimeoCont.dataset['key']
        console.log('will play')
        players[parseInt(key)].play()
      } else {
        players.forEach(p => {
          if(p.playing) {
            p.pause()
          }
        })
      }
    }
  });

  nextSlideBtn.addEventListener('click', function(){
    console.log('[ProjectSwiper] slideNext')
    projectSwiper.slideNext();
  });

  prevSlideBtn.addEventListener('click', function(){
    console.log('[ProjectSwiper] slidePrev')
    projectSwiper.slidePrev();
  });

  return projectSwiper;
}

// export const initBookPageSlider = () => {
//   // var slidesLength = document.querySelectorAll('.swiper-slide').length;

//   Swiper.use([Autoplay, Lazy, EffectFade]);
//   var mySwiper = new Swiper('.swiper-container.book-page-swiper-cnt', {
//     // slidesPerView: 'auto',
//     spaceBetween: 30,
//     // effect: 'fade',
//     // fadeEffect: {
//     //   crossFade: true
//     // },
//     slidesPerView: 1,
//     centeredSlides: true,
//     loop: true,
//     grabCursor: true,
//     speed: 500,
//     // autoplay: {
//     //   delay: 3000,
//     //   disableOnInteraction: false,
//     // },
//     lazy: {
//       loadPrevNext: true,
//       // loadPrevNextAmount: 3,
//     },
//     breakpoints: {
//       768: {
//         slidesPerView: 1.6,
//       },
//     },
//     watchSlidesVisibility: true,
//   });

//   var infoSwiper = new Swiper('.swiper-container.book-page-info-cnt', {
//     // slidesPerView: 'auto',
//     effect: 'fade',
//     fadeEffect: {
//       crossFade: true
//     },
//     slidesPerView: 1,
//     // spaceBetween: 30,
//     centeredSlides: true,
//     loop: false,
//     // grabCursor: true,
//     speed: 500,
//     allowTouchMove: false,
//     autoHeight: true,
//     // autoplay: {
//     //   delay: 3000,
//     //   disableOnInteraction: false,
//     // },
//     lazy: {
//       loadPrevNext: true,
//       // loadPrevNextAmount: 3,
//     },
//     watchSlidesVisibility: true,
//   });

//   const prevBookBtn = document.querySelector('.prev-book');
//   const nextBookBtn = document.querySelector('.next-book');

//   nextBookBtn.addEventListener('click', function(){
//     mySwiper.slideNext();
//     // infoSwiper.slideNext();
//   });

//   prevBookBtn.addEventListener('click', function(){
//     mySwiper.slidePrev();
//     // infoSwiper.slidePrev();
//   });

//   mySwiper.on('slideChange', function () {
//     infoSwiper.slideTo(mySwiper.realIndex);
//   });

//   mySwiper.on('slideChangeTransitionEnd', function () {
//     const bookPreview = document.querySelector(".swiper-slide-active .book-preview");
//     if(bookPreview){
//       document.querySelector(".swiper-slide-active .preview-btn").addEventListener('click', function(){
//         bookPreview.classList.toggle('book-opened');
//         setTimeout(()=>{
//           infoSwiper.updateAutoHeight(500);
//         }, 500);
//         return false;
//       });
//     }

//     // const pdfViewer = document.querySelector('.swiper-slide-active .pdf-viewer');
//     // if (pdfViewer) {
//     //   const pdfUrl = document.querySelector('.swiper-slide-active .pdf-viewer').dataset.pdf;
//     //   PDFObject.embed(pdfUrl, ".swiper-slide-active .pdf-viewer", { pdfOpenParams: { view: 'FitH' } });
//     // }

//     // const title = document.querySelector('.book-page-swiper-cnt .swiper-slide-active .title').textContent;
//     // const url = document.querySelector('.book-page-swiper-cnt .swiper-slide-active').dataset.url;

//     // window.history.pushState({}, title, url);
//   });

//   return infoSwiper;
// }