export const startClock = () => {
  // var date = new Date();
  // var h = date.getHours();
  // var m = date.getMinutes();
  // var clockEl = document.getElementById('clock');

  // var clockHourEl = document.querySelector('#clock .hour');
  // var clockDotsEl = document.querySelector('#clock .dots');
  // var clockMinutesEl = document.querySelector('#clock .minutes');

  // if (h < 10) { h = '0' + h; }
  // if (m < 10) { m = '0' + m; }

  // clockHourEl.innerHTML= h;
  // clockMinutesEl.innerHTML= m;
  // clockDotsEl.classList.toggle('visible');

  // var t = setTimeout(startClock, 1000);

  const clockEls = document.querySelectorAll('.contact-time');



  return setInterval(() => {
    const timeNow = (new Date()).toLocaleTimeString();
    clockEls.forEach(el => el.innerHTML = `ATHENS, ${timeNow}`);
  }, 1000);
}